<template>
  <div class="row">
    <div class="col-lg-12  d-flex justify-content-end mb-h" v-if="isNotWizard">
      <div class="col-lg-6 p-0">
        <div class="float-right">
          <WButton info
            v-if="!isDisable"
            @click="openFirewalleWizard"
          >
            {{ $t('firewall.addFirewall') }}
          </WButton>
        </div>
      </div>
    </div>
    <div class="col-lg-12" v-if="isNotWizard">
      <div class="row">
        <div class=" col-lg-12 ">
          <div class="row mb-1 mt-0">
            <div class="col-lg-12">
              <div class="row pl-0 w-100 justify-content-start ml-0">
<!--                <div class="filters__multiple">-->
<!--                  <div class="input-group input-group-sm filters__input">-->
<!--                  <span class="input-group-addon filters__field-icon"><i-->
<!--                    class="fa fa-map-marker"></i></span>-->
<!--                    <select @change="refreshFirewallsList"-->
<!--                            class="form-control form-control-sm filters__field"-->
<!--                            v-model="filters.location">-->
<!--                      <option :selected="true" :value="'All locations'">{{-->
<!--                        $t('general.allLocations')-->
<!--                        }}-->
<!--                      </option>-->
<!--                      <option v-for="location in locationsList" :value="location.id"-->
<!--                              :key="location.id">-->
<!--                        {{ location.name }}-->
<!--                      </option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </div>-->
<!--                <div-->
<!--                  class=" d-flex  align-items-center switcher-with-childs-for-use-in-filters-wrapper">-->
<!--                  <div-->
<!--                    class="  d-flex align-items-end justify-content-start"-->
<!--                  >-->
<!--                    <Switch-component-->
<!--                      :disabled="this.filters.location === 'All locations'"-->
<!--                      v-model="filters.with_childs"-->
<!--                      class="switcher-with-childs-for-use-in-filters"-->
<!--                      @change="refreshFirewallsList"-->
<!--                    />-->
<!--                  </div>-->
<!--                  <div-->
<!--                    class="input-group input-group-sm filters__location switcher-with-childs-for-use-in-filters-caption ">-->
<!--                    <small class="with-child">{{ $t('general.withChild') }}</small>-->
<!--                  </div>-->
<!--                </div>-->
                                            <div class="col-lg-3 p-0 pr-1" >
            <div class=" ">
              <div class=" ">
                <div class="input-group input-group-sm ">
                  <span class="input-group-addon"><i class="fa fa-search"></i></span>
                  <input
                    id="search"
                    name="search"
                    :placeholder="$t('general.searchPlaceHolder')"
                    class="form-control"
                    v-model="filters.query"
                  />
                  <span class="clear-input text-muted" v-if="filters.query != ''" @click="filters.query = ''">
                    <i class="fa fa-remove"></i>
                  </span>
                </div>
              </div>
            </div>
            </div>
                                <div class="col-lg-3  p-0 " v-if="areLocationsVisible">
                                                                                                            <div
                  class="input-group-for-location-select-in-firewall--filters   ">
                                <span class="input-group-addon filters__field-icon">
                  <i class="fa fa-map-marker"
                     :class="{ 'text-primary': filters.location !== 'All locations' }"></i>
                </span>
                  <SelectComponentV3ForUseInLocationSelectors

                    v-if="showLocationFilter"
                    :show-search-field-into-items-list="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                    no-wrap-selected-option
                    class="select-location-in-firewall--filters"
                    enable-max-option-width
                    small-size
                     :no-clear-selected-option-when-click-on-it="isSelectedDefaultLocationForRequests"
                   :show-delete-button="!isSelectedDefaultLocationForRequests"
                    v-model="locationInFiltersData"
                    track-by="name"
                    option-id-name="id"
                    :no-options-found="$t('general.noResultForSearch')"
                    :async-function="requestLocations.bind(this)"
                    :placeholder="$t('portalStats.selectLocation')"
                    @select="selectLocationInFilters"
                  >
                    <template #dropdown-start>
                      <div class="ml-h mt-1">
                        <Switch-component
                          v-if="!isSelectedDefaultLocationForRequests || (isSelectedDefaultLocationForRequests && $store.getters.getDefaultWithChildsForRequests())"
                          @input="refreshFirewallsList"
                          :label="$t('general.withChild')"
                          v-model="filters.with_childs"
                          class=""
                        />
                      </div>
                    </template>
                  </SelectComponentV3ForUseInLocationSelectors>
                </div>
                </div>
<!--                              <div class="float-right">-->
<!--                              <button class="btn btn-warning btn-md float-right" @click="openFirewalleWizard" v-if="!isDisable">-->
<!--                {{ $t('firewall.addFirewall') }}-->
<!--              </button>-->
<!--              </div>-->
<!--                              <div class="col-lg-6 p-0">-->
<!--                              <div class="float-right">-->
<!--                              <button class="btn btn-warning btn-md float-right" @click="openFirewalleWizard" v-if="!isDisable">-->
<!--                {{ $t('firewall.addFirewall') }}-->
<!--              </button>-->
<!--              </div>-->
<!--              </div>-->
              </div>
              <div class="row w-100 justify-content-start mt-q">
                <div v-if="isFiltersActive" class="mb-1 ml-1">
                  <WButton secondary outline sm
                    @click="resetAllFilters"
                  >
                    <i class="fa fa-close"></i>
                    {{ $t('general.resetAllFilters') }}
                  </WButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-12" v-if="isNotWizard">
      <section class="firewalls" :class="{ 'edit-visible': editVisible }">
        <section class="firewalls-list animated fadeIn">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <div v-if="isFirewallListLoading"><Loader-spinner></Loader-spinner></div>
                <!--<div class="loader loader-backdrop loader-backdrop-firewall" v-if=""></div>-->
                <div class="card-header">
                  <div>
                    <i class="fa fa-align-justify"></i>
                    {{ $t('sidebar.firewall') }}
                  </div>
                </div>
                <div class="card-block">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>{{ $t('general.name') }}</th>
                        <th v-if="!editVisible">{{ $t('firewall.Policy') }}</th>
                        <th v-if="!editVisible">{{ $t('firewall.direction') }}</th>
                        <th v-if="!editVisible">{{ $t('firewall.Rules') }}</th>
                        <th v-if="!editVisible && areLocationsVisible">{{ $t('general.baseLocation') }}</th>
                        <th v-if="!editVisible"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(firewall, index) in filterItems(firewallsList)"
                        :data-index="index"
                        :data-id="firewall.id"
                        class="table-row"
                        :class="{
                          selected: firewall.id === editFirewallId,
                          'firewall-loading': firewallSpinner(firewall.id)
                        }"
                        @click="toggleEdit"
                      >
                        <td>{{ firewall.name }}</td>
                        <td v-if="!editVisible">
                          <span v-if="!firewall.policy">—</span>
                          <span v-else>{{ firewall.policy }}</span>
                        </td>
<!--                        <td v-if="!editVisible">{{ firewall.direction }}</td>-->
                        <td v-if="!editVisible">{{ getDirectionTypeWithI18n(firewall.direction) }}</td>
                        <td v-if="!editVisible">
                          <span v-if="firewall.rules.length" class="badge badge-pill mr-q badge-light badge-info">
                            {{ firewall.rules.length }}
                          </span>
                          <span class="badge badge-pill mr-q badge-light badge-default" v-else>0</span>
                        </td>
                        <td v-if="!editVisible && areLocationsVisible" class="responsive-row">{{ locationName(firewall.base_location) }}</td>
                        <td class="action-row" v-if="!editVisible && !isDisable">
                          <i
                            class="fa fa-remove fa-lg mr-q text-danger user-action"
                            data-action="delete"
                            @click="openDeletingDialog(firewall.id)"
                          ></i>
                        </td>
                      </tr>
                      <tr v-if="!firewallsList.length">
                        <td colspan="5" class="no-data-row">
                          <span class="text-muted">{{ $t('aps.noDataToDisplay') }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section class="firewall-edit" v-if="editVisible">
          <div class="row sticky-top">
            <div class="col-lg-12">
              <div class="card">
                <div class="card-block">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="edit-header mb-1">
                        <div class="edit-header__info">
                          <div class="edit-header__name">
                            <span class="h4">{{ firewallEditCurrent.name }}</span>
                          </div>
                          <WButton secondary outline sm
                            customClass="edit-btn-close"
                            @click="disableEditMode"
                          >
                            <i class="fa fa-close"></i>
                          </WButton>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col-lg-6">
                          <div class="form-group">
                            <div class="h5 mt-1 mb-1">
                              {{ $t('firewall.firewall') }}
                              <info
                                @clicked="showFirewallInfo"
                                class="info-tooltip-in-firewall"
                                :content="$t('firewall.infoTooltip')"
                              />
                            </div>
                            <Alert
                              v-if="showingFirewallInfo"
                              closable
                              class="alert-info animated fadeIn"
                              @alert-closed="showingFirewallInfo = false"
                            >
                              <template #text>
                                <span class="mr-h">
                                  {{ $t('firewall.infoMessage') }}:
                                  <a href="https://wiki.archlinux.org/index.php/Iptables">
                                    https://wiki.archlinux.org/index.php/Iptables
                                  </a>
                                </span>
                              </template>
                            </Alert>
                            <label for="name">{{ $t('aps.name') }}</label>
                            <input
                              type="text"
                              id="name"
                              :placeholder="$t('firewall.firewallNamePlaceHolder')"
                              name="name"
                              :disabled="isDisable"
                              v-validate="'max:30'"
                              class="form-control"
                              :class="{ input: true, 'is-danger': errors.has('name') }"
                              v-model="updatedFirewall.name"
                            />
                            <span v-show="errors.has('name')" class="help is-danger">{{ errors.first('name') }}</span>
                          </div>
                          <div class="form-group" v-if="areLocationsVisible">
                            <label for="base_location">{{ $t('aps.location') }}</label>
                            <select
                              v-model="updatedFirewall.base_location"
                              name="location"
                              class="form-control"
                              id="base_location"
                              :class="{ 'select-disabled': isDisable }"
                              :disabled="isDisable"
                            >
                              <option v-for="location in locationsList" :key="location.id" :value="location.id">
                                {{ location.name }}
                              </option>
                            </select>
                          </div>
                          <div class="form-group">
                            <label for="policy">{{ $t('firewall.policy') }}</label>
                            <select
                              v-model="updatedFirewall.policy"
                              :value="updatedFirewall.policy"
                              name="location"
                              class="form-control"
                              id="policy"
                              :disabled="isDisable"
                              :class="{ 'select-disabled': isDisable }"
                            >
                              <option>ACCEPT</option>
                              <option>DROP</option>
                              <option>RETURN</option>
                            </select>
                          </div>

                          <div class="form-group">
                            <label for="direction">{{ $t('firewall.direction') }}</label>
                            <select
                              v-model="updatedFirewall.direction"
                              :value="updatedFirewall.policy"
                              name="location"
                              class="form-control"
                              id="direction"
                              :disabled="isDisable"
                              :class="{ 'select-disabled': isDisable }"
                            >
<!--                              <option>ANY</option>-->
<!--                              <option>IN</option>-->
<!--                              <option>OUT</option>-->
                              <option v-for="directionType of directionTypes" :value="directionType">
                                {{getDirectionTypeWithI18n(directionType)}}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="rules col-lg-12">
                      <div
                        class="card animated fadeIn fadeOut"
                        @click="toggleRuleEdit"
                        v-if="updatedFirewall.rules.length"
                        :data-index="index"
                        v-for="(rule, index) in updatedFirewall.rules"
                        :class="{
                          'rule-disable': isDisable,
                          'border-secondary': rule.jump === 'RETURN',
                          'border-danger': rule.jump === 'DROP',
                          'border-success': rule.jump === 'ACCEPT',
                          'text-white': index === ruleIndexForEdit,
                          'bg-success': rule.jump === 'ACCEPT' && index === ruleIndexForEdit,
                          'bg-danger': rule.jump === 'DROP' && index === ruleIndexForEdit
                        }"
                      >
                        <div class="card-body">
                          <div class="h1 text-right mb-4">
                            <small class="text-muted mr-2"># {{ index + 1 }}</small>
                            <!--<i class="icon-check text-success" v-if="rule.jump === 'ACCEPT'"></i>-->
                            <!--<i class="icon-close text-danger" v-if="rule.jump === 'DROP'"></i>-->
                          </div>
                          <div class="h4 mb-0">
                            <span v-if="rule.jump">{{ rule.jump }}</span>
                          </div>
                          <small class="text-uppercase font-weight-bold mr-1">
                            {{ $t('firewall.ruleProtocol') }}:
                            <span v-if="rule.protocol" class="text-muted">{{ rule.protocol }}</span>
                            <span class="text-muted" v-else>—</span>
                          </small>
                          <small class="text-uppercase font-weight-bold">
                            {{ $t('firewall.ruleIPProtocol') }}:
                            <span v-if="rule.ip_protocol" class="text-muted">{{ rule.ip_protocol }}</span>
                            <span class="text-muted" v-else>—</span>
                          </small>
                          <div class="row" v-if="rule.destination.length || rule.source.length">
                            <div class="col-lg-6">
                              <div class="h6 mb-q mt-1">{{ $t('firewall.fromMAC') }}</div>
                              <small
                                v-if="rule.source.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                                v-for="mac in rule.source"
                              >
                                {{ mac }}
                              </small>
                              <small
                                v-if="!rule.source.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                              >
                                -
                              </small>
                            </div>
                            <div class="col-lg-6">
                              <div class="h6 mb-q mt-1">{{ $t('firewall.toMAC') }}</div>
                              <small
                                v-if="rule.destination.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                                v-for="mac in rule.destination"
                              >
                                {{ mac }}
                              </small>
                              <small
                                v-if="!rule.destination.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                              >
                                -
                              </small>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="rule.protocol === 'ipv4' && (rule.ip_destination.length || rule.ip_source.length)"
                          >
                            <div class="col-lg-6">
                              <div class="h6 mb-q mt-1">{{ $t('firewall.fromIP') }}</div>
                              <small
                                v-if="rule.ip_source.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                                v-for="ip in rule.ip_source"
                              >
                                {{ ip }}
                              </small>
                              <small
                                v-if="!rule.ip_source.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                              >
                                -
                              </small>
                            </div>
                            <div class="col-lg-6">
                              <div class="h6 mb-q mt-1">{{ $t('firewall.toIP') }}</div>
                              <small
                                v-if="rule.ip_destination.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                                v-for="ip in rule.ip_destination"
                              >
                                {{ ip }}
                              </small>
                              <small
                                v-if="!rule.ip_destination.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                              >
                                -
                              </small>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              (rule.ip_protocol === 'tcp' || rule.ip_protocol === 'udp') &&
                              (rule.ip_destination_port.length || rule.ip_source_port.length)
                            "
                          >
                            <div class="col-lg-6">
                              <div class="h6 mb-q mt-1">{{ $t('firewall.fromPort') }}</div>
                              <small
                                v-if="rule.ip_source_port.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                                v-for="port in rule.ip_source_port"
                              >
                                {{ port }}
                              </small>
                              <small
                                v-if="!rule.ip_source_port.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                              >
                                -
                              </small>
                            </div>
                            <div class="col-lg-6">
                              <div class="h6 mb-q mt-1">{{ $t('firewall.toPort') }}</div>
                              <small
                                v-if="rule.ip_destination_port.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                                v-for="port in rule.ip_destination_port"
                              >
                                {{ port }}
                              </small>
                              <small
                                v-if="!rule.ip_destination_port.length"
                                class="text-muted text-uppercase font-weight-bold rule-value"
                              >
                                -
                              </small>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="card animated fadeIn fadeOut border-secondary rule"
                        @click="editRuleModal = true"
                        v-if="!isDisable"
                      >
                        <div class="card-body">
                          <div class="h1 text-right mb-4">
                            <i class="glyphicons glyphicons-plus text-muted"></i>
                            <!--<i class="icon-close text-danger" v-if="rule.jump === 'DROP'"></i>-->
                          </div>
                          <div class="rule-title">
                            <div class="h4 text-muted text-uppercase">{{ $t('firewall.AddRule') }}</div>
                            <small class="text-muted text-uppercase font-weight-bold">
                              {{ $t('firewall.AddRule') }} #{{ updatedFirewall.rules.length + 1 }}
                            </small>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div class="actions-buttons" :class="{ 'actions-buttons__operator': isDisable }">
                  <div>
                    <WButton success outline
                      customClass="mr-1"
                      v-if="!isDisable"
                      @click="updateFirewall"
                    >
                      {{ $t('aps.save') }}
                    </WButton>
                  </div>
                  <div>
                    <WButton danger outline
                      customClass="mr-1"
                      v-if="!isDisable"
                      @click="openDeletingDialog(editFirewallId)"
                    >
                      {{ $t('general.delete') }}
                    </WButton>
                    <WButton secondary outline
                      @click="disableEditMode"
                    >
                      <span v-if="isDisable">{{ $t('aps.close') }}</span>
                      <span v-else>{{ $t('aps.cancel') }}</span>
                    </WButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
    <div class="col-lg-12"><router-view></router-view></div>
    <modal title="Delete Firewall" v-model="deletingFirewallModal" class="modal-danger" effect="fade/zoom">
      <h4 slot="modal-title" class="modal-title">{{ $t('firewall.modalDeleteFirewallTitle') }}</h4>
      {{ $t('firewall.modalDeleteFirewallText') }}
      <b>{{ firewallName(firewallIdToDelete) }}</b>
      ?
      <div slot="modal-footer" class="modal-footer">
        <WButton secondary
          @click="cancelDeleting"
        >
          {{ $t('general.cancel') }}
        </WButton>
        <WButton danger outline
          @click="deleteFirewall"
        >
          {{ $t('general.delete') }}
        </WButton>
      </div>
    </modal>

    <modal
      :title="this.$t('firewall.RuleSettings')"
      large
      @cancel="cancelRule"
      v-model="editRuleModal"
      class="modal-success"
      effect="fade/zoom"
    >
      <h4 slot="modal-title" class="modal-title">{{ $t('firewall.RuleSettings') }}</h4>

      <div class="">
        <div class="h5 m-0" v-if="ruleIndexForEdit || ruleIndexForEdit === 0">
          {{ $t('firewall.editRule') }} #{{ ruleIndexForEdit + 1 }}
        </div>
        <div class="h5 m-0" v-else>{{ $t('firewall.addNewRule') }}</div>
        <div class="h6 font-weight-bold m-0 mt-2">{{ $t('firewall.Linklayer') }}</div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="source">{{ $t('firewall.fromMACaddress') }}</label>
              <div class="input-group">
                <input
                  type="text"
                  name="source"
                  id="source"
                  :placeholder="$t('firewall.enterMACPlaceholder')"
                  class="form-control"
                  v-validate="'mac'"
                  @keyup.13="addValue('source')"
                  :class="{ input: true, 'is-danger': errors.has('source') }"
                  v-model="newSource"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('source')"
                    :disabled="newSource === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('source', true)"
                    :disabled="newSource === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('source')" class="help is-danger">{{ errors.first('source') }}</span>
              <a
                class="text-muted mr-1 l2-value"
                v-for="(mac, index) in newRule.source"
                :class="{ 'text-warning': mac[0] === '!' }"
                :data-id="index"
                data-type="source"
                @click="removeValue"
              >
                {{ mac.toUpperCase() }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="destination">{{ $t('firewall.toMACaddress') }}</label>
              <div class="input-group">
                <!--<span class="input-group-btn un-exclamation">-->
                <!--<button class="btn btn-warning mb-0"-->
                <!--type="button"-->
                <!--@click="addValue('destination')"><i class="fa fa-exclamation"></i></button>-->
                <!--</span>-->
                <input
                  type="text"
                  name="destination"
                  id="destination"
                  :placeholder="$t('firewall.enterMACPlaceholder')"
                  class="form-control"
                  v-validate="'mac'"
                  @keyup.13="addValue('destination')"
                  :class="{ input: true, 'is-danger': errors.has('destination') }"
                  v-model="newDestination"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('destination')"
                    :disabled="newDestination === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('destination', true)"
                    :disabled="newDestination === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('destination')" class="help is-danger">{{ errors.first('destination') }}</span>
              <a
                class="text-muted mr-1 l2-value"
                v-for="(mac, index) in newRule.destination"
                :class="{ 'text-warning': mac[0] === '!' }"
                :data-id="index"
                data-type="destination"
                @click="removeValue"
              >
                {{ mac.toUpperCase() }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="h6 m-0 mt-2 font-weight-bold">{{ $t('firewall.Internetlayer') }}</div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="protocol">{{ $t('firewall.ruleProtocol') }}</label>
              <select
                v-model="newRule.protocol"
                :value="newRule.protocol"
                name="protocol"
                class="form-control"
                id="protocol"
              >
                <option :value="''">—</option>
                <option>ipv4</option>
                <option>ether-arp</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="ip-source">{{ $t('firewall.fromIPaddress') }}</label>
              <div class="input-group">
                <input
                  type="text"
                  name="ip-source"
                  id="ip-source"
                  :placeholder="$t('firewall.enterIPPlaceholder')"
                  class="form-control"
                  @keyup.13="addValue('ip_source')"
                  :disabled="newRule.protocol !== 'ipv4'"
                  v-validate="'ipv4mask'"
                  :class="{ input: true, 'is-danger': errors.has('ip-source') }"
                  v-model="newIpSource"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('ip_source')"
                    :disabled="newIpSource === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('ip_source', true)"
                    :disabled="newIpSource === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('ip-source')" class="help is-danger">{{ errors.first('ip-source') }}</span>
              <a
                class="text-muted mr-1 l2-value"
                v-if="newRule.protocol === 'ipv4'"
                v-for="(ip, index) in newRule.ip_source"
                :class="{ 'text-warning': ip[0] === '!' }"
                :data-id="index"
                data-type="ip_source"
                @click="removeValue"
              >
                {{ ip }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="ip-destination">{{ $t('firewall.toIPaddress') }}</label>
              <div class="input-group">
                <input
                  type="text"
                  name="ip-destination"
                  id="ip-destination"
                  :placeholder="$t('firewall.enterIPPlaceholder')"
                  class="form-control"
                  v-validate="'ipv4mask'"
                  :disabled="newRule.protocol !== 'ipv4'"
                  @keyup.13="addValue('ip_destination')"
                  :class="{ input: true, 'is-danger': errors.has('ip-destination') }"
                  v-model="newIpDestination"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('ip_destination')"
                    :disabled="newIpDestination === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('ip_destination', true)"
                    :disabled="newIpDestination === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('ip-destination')" class="help is-danger">
                {{ errors.first('ip-destination') }}
              </span>
              <a
                class="text-muted mr-1 l2-value"
                v-if="newRule.protocol === 'ipv4'"
                :class="{ 'text-warning': ip[0] === '!' }"
                v-for="(ip, index) in newRule.ip_destination"
                :data-id="index"
                data-type="ip_destination"
                @click="removeValue"
              >
                {{ ip }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="h6 m-0 mt-2 font-weight-bold">{{ $t('firewall.Transportlayer') }}</div>
        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="ip_protocol">{{ $t('firewall.ruleIPProtocol') }}</label>
              <select
                v-model="newRule.ip_protocol"
                :disabled="newRule.protocol !== 'ipv4'"
                :class="{ 'select-disabled': newRule.protocol !== 'ipv4' }"
                :value="newRule.ip_protocol"
                name="ip_protocol"
                class="form-control"
                id="ip_protocol"
              >
                <option :value="''">—</option>
                <option>tcp</option>
                <option>udp</option>
                <option>icmp</option>
                <option>dccp</option>
                <option>sctp</option>
                <option>l2tp</option>
                <option>esp</option>
              </select>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="ip-source">{{ $t('firewall.fromPortModal') }}</label>
              <div class="input-group">
                <input
                  type="text"
                  name="port-source"
                  id="port-source"
                  :placeholder="$t('firewall.enterPortPlaceholder')"
                  class="form-control"
                  v-validate="'numeric|between:1,65536'"
                  @keyup.13="addValue('ip_source_port')"
                  :disabled="!(newRule.ip_protocol === 'tcp' || newRule.ip_protocol === 'udp')"
                  :class="{ input: true, 'is-danger': errors.has('port-source') }"
                  v-model="newPortSource"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('ip_source_port')"
                    :disabled="newPortSource === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('ip_source_port', true)"
                    :disabled="newPortSource === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('port-source')" class="help is-danger">{{ errors.first('port-source') }}</span>
              <a
                class="text-muted mr-1 l2-value"
                v-if="newRule.ip_protocol === 'tcp' || newRule.ip_protocol === 'udp'"
                v-for="(port, index) in newRule.ip_source_port"
                :class="{ 'text-warning': port[0] === '!' }"
                :data-id="index"
                data-type="ip_source_port"
                @click="removeValue"
              >
                {{ port }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="ip-destination">{{ $t('firewall.toPortModal') }}</label>
              <div class="input-group">
                <input
                  type="text"
                  name="port-destination"
                  id="port-destination"
                  :placeholder="$t('firewall.enterPortPlaceholder')"
                  class="form-control"
                  @keyup.13="addValue('ip_destination_port')"
                  v-validate="'numeric|between:1,65536'"
                  :disabled="!(newRule.ip_protocol === 'tcp' || newRule.ip_protocol === 'udp')"
                  :class="{ input: true, 'is-danger': errors.has('port-destination') }"
                  v-model="newPortDestination"
                />
                <span class="input-group-btn">
                  <WButton success
                    @click="addValue('ip_destination_port')"
                    :disabled="newPortDestination === ''"
                  >
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
                <span class="input-group-btn">
                  <WButton warning
                    customClass="ml-q"
                    @click="addValue('ip_destination_port', true)"
                    :disabled="newPortDestination === ''"
                  >
                    <i class="fa fa-exclamation"></i>
                    <i class="fa fa-plus"></i>
                  </WButton>
                </span>
              </div>
              <span v-show="errors.has('port-destination')" class="help is-danger">
                {{ errors.first('port-destination') }}
              </span>
              <a
                class="text-muted mr-1 l2-value"
                v-for="(port, index) in newRule.ip_destination_port"
                :class="{ 'text-warning': port[0] === '!' }"
                v-if="newRule.ip_protocol === 'tcp' || newRule.ip_protocol === 'udp'"
                :data-id="index"
                data-type="ip_destination_port"
                @click="removeValue"
              >
                {{ port }}
                <i class="fa fa-remove text-danger"></i>
              </a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-6">
            <div class="form-group mt-1">
              <label for="jump">{{ $t('firewall.Action') }}</label>
              <select v-model="newRule.jump" :value="newRule.jump" name="location" class="form-control" id="jump">
                <option>ACCEPT</option>
                <option>DROP</option>
                <option>RETURN</option>
              </select>
            </div>
          </div>
          <div class="col-lg-4"></div>
        </div>
        <!--<div class="row mt-2">-->
        <!--<div class="col-lg-4">-->
        <!--<button type="button" v-if="ruleIndexForEdit === false" class="btn btn-outline-success btn-block"-->
        <!--@click="pushNewRule">Add Rule-->
        <!--</button>-->
        <!--<button type="button" v-if="ruleIndexForEdit || ruleIndexForEdit === 0" class="btn btn-outline-success btn-block"-->
        <!--@click="pushNewRule">Save Rule #{{ruleIndexForEdit + 1}}-->
        <!--</button>-->
        <!--</div>-->
        <!--<div class="col-lg-4">-->
        <!--<button type="button" v-if="ruleIndexForEdit || ruleIndexForEdit === 0" class="btn btn-outline-secondary btn-block"-->
        <!--@click="cancelRule">Cancel-->
        <!--</button>-->
        <!--</div>-->
        <!--<div class="col-lg-4">-->
        <!--<button type="button" v-if="ruleIndexForEdit || ruleIndexForEdit === 0" class="btn btn-outline-danger btn-block"-->
        <!--@click="deleteRule">Delete Rule #{{ruleIndexForEdit + 1}}-->
        <!--</button>-->
        <!--</div>-->
        <!--</div>-->
      </div>

      <div slot="modal-footer" class="modal-footer">
        <div class="col-lg-4">
          <WButton success outline fullWidth
            v-if="ruleIndexForEdit === false"
            @click="pushNewRule"
            :disabled="errors.any()"
          >
            {{ $t('firewall.AddRule') }}
          </WButton>
          <WButton success outline fullWidth
            v-if="ruleIndexForEdit || ruleIndexForEdit === 0"
            @click="pushNewRule"
            :disabled="errors.any()"
          >
            {{ $t('firewall.SaveRule') }} #{{ ruleIndexForEdit + 1 }}
          </WButton>
        </div>
        <div class="col-lg-4">
          <WButton secondary outline fullWidth
            @click="cancelRule"
          >
            {{ $t('general.cancel') }}
          </WButton>
        </div>
        <div class="col-lg-4">
          <WButton danger outline fullWidth
            v-if="ruleIndexForEdit || ruleIndexForEdit === 0"
            @click="deleteRule"
          >
            {{ $t('firewall.deleteRuleButton') }} #{{ ruleIndexForEdit + 1 }}
          </WButton>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import Vue from 'vue';
import VueNotifications from 'vue-notifications';
import Alert from '../../components/Universal/alert/alert.vue';
import Modal from '../../components/Modal.vue';
import commonService from '../../services/commonService';
import firewallService from '../../services/firewallService';
import firewallPollingService from '../../services/firewallPollingService';
import Info from '../../components/Universal/info-icon.vue';
import SwitchComponent from '../../components/Universal/Switch-component.vue';
import SelectComponentV2 from '../../components/Universal/select/select-componentV2.vue';
import locationService from '../../services/locationService';
import SelectComponentV3ForUseInLocationSelectors from '../../components/Universal/select/select-componentV3ForUseInLocationSelectors.vue';


const DIRECTION_TYPES = ['ANY', 'IN', 'OUT'];

export default {
  name: 'Firewall',
  components: { Modal, Info, Alert, SwitchComponent, SelectComponentV2, SelectComponentV3ForUseInLocationSelectors },
  data() {
    return {
      showLocationFilter: true,
      locationInFiltersData: [],
      showingFirewallInfo: false,
      newRule: {
        destination: [],
        source: [],
        ip_destination: [],
        ip_source: [],
        ip_destination_port: [],
        ip_source_port: [],
        ip_protocol: '',
        jump: 'ACCEPT',
        protocol: ''
      },
      updatedFirewall: {},
      ruleIndexForEdit: false,
      firewallIdToDelete: false,
      deletingFirewallModal: false,
      editRuleModal: false,
      editInited: false,
      newDestination: '',
      newSource: '',
      newIpDestination: '',
      newIpSource: '',
      newPortDestination: '',
      newPortSource: '',
      filters: {
        location: 'All locations',
        with_childs: false,
        query: '',
      }

    };
  },
  computed: {
    directionTypes() {
      return DIRECTION_TYPES;
    },
    isSelectedDefaultLocationForRequests() {
      if (this.$store.getters.getDefaultLocationForRequests(this)) {
        return true;
      } else {
        return false;
      }
    },
    isFirewallListLoading(){
      return this.$store.state.loadingFirewalls;
    },
    userBaseLocation() {
      return this.$store.state.userData.base_location;
    },
    locationsList() {
      // return this.$store.state.locationsList;
      // return helpers.sortLocationsList(this.$store.state.locationsList);
      return this.$store.getters.locationsListSorted;
    },
    // locationsListForSelectLocationField() {
    //   // return this.$store.state.locationsList;
    //   return this.$store.getters.locationsListSorted;
    // },
    isDisable() {
      return this.$store.state.userData.role === 'operator';
    },
    editVisible() {
      return this.$store.state.editFirewallId;
    },
    isNotWizard() {
      return this.$route.name === 'Firewall';
    },
    editFirewallId() {
      return this.$store.state.editFirewallId;
    },
    firewallsList() {
      return this.$store.state.firewallsList;
    },
    isFiltersActive() {
      return (
        this.filters.location !== 'All locations' ||
        this.filters.query !== ''
      );
    },
    firewallOperations() {
      return this.$store.state.firewallOperations;
    },
    firewallEditCurrent() {
      this.editInited = false;
      const { firewallsList } = this.$store.state;
      let currentFirewall;
      firewallsList.forEach((firewall) => {
        if (firewall.id == this.$store.state.editFirewallId) {
          currentFirewall = firewall;
        }
      });
      this.updatedFirewall = JSON.parse(JSON.stringify(currentFirewall));
      return currentFirewall;
      this.editInited = true;
    },
    ruleProtocol() {
      return this.newRule.protocol;
    }
  },
  watch: {
    firewallsList() {
      firewallPollingService.startPolling(this);
    },
    firewallOperations() {
      this.firewallSpinner();
    },
    ruleProtocol() {
      if (!this.newRule.protocol) {
        this.newRule.ip_protocol = '';
      }
    }
  },
  methods: {
    getDirectionTypeWithI18n(typeName) {
      if (this.$t(`firewall.${typeName}`)) {
        return this.$t(`firewall.${typeName}`)
      } else {
        return typeName
      }
    },
    showFirewallInfo() {
      // console.log('clicked');
      this.showingFirewallInfo = true;
    },
    toggleEdit(e) {
      if (e.target.dataset.action === 'delete') {
        return;
      }
      this.ruleIndexForEdit = false;
      const selectedNode = e.target.closest('[data-id]');
      const { id } = selectedNode.dataset;
      this.$store.commit('setFirewallEditId', id);
    },
    selectLocationInFilters(location) {
      // console.log(location)
      if (!location) {
        this.filters.location = 'All locations'
      } else {
        this.filters.location = location.id;
        // this.addLocationInPreviouslySelectedLocationsInFilter(location)
      }
      this.refreshFirewallsList()

    },
    async requestLocations(searchQuery) {
      // return locationService.requestLocations(searchQuery);
      // return locationService.requestLocationsWithSort(searchQuery);
      return locationService.requestLocationsWithSortAndFilteringByChildsForLocationSelectInPages(this, searchQuery)
    },
    openDeletingDialog(id) {
      this.firewallIdToDelete = id;
      this.deletingFirewallModal = true;
    },
    filterItems(items) {
      let filtered = items;

      filtered = this.firewallSearchQuery(filtered, this.filters.query);
      return filtered;
    },
    firewallSearchQuery (firewallArray, searchQuery){
    if (!firewallArray) {
      return [];
    }
    return firewallArray.filter(
      (item) => item.name.toLowerCase().indexOf(searchQuery.toLowerCase()) !== -1
    );
  },
    // resetAllFilters() {
    //   this.disableEditMode();
    //   this.locationInFiltersData = [];
    //   this.toggleShowLocationFilter();
    //   this.filters = {
    //     query: '',
    //     location: 'All locations',
    //     with_childs: false
    //   };
    //   this.refreshFirewallsList();
    // },
    resetAllFilters() {
      this.disableEditMode();
      // this.locationInFiltersData = [];
      // this.toggleShowLocationFilter();
      this.filters = {
        query: '',
        // location: 'All locations',
        // with_childs: false
      };
      if (!this.isSelectedDefaultLocationForRequests) {
        this.filters.location = 'All locations';
        this.filters.with_childs = false;
        this.locationInFiltersData = [];
      } else {
        this.filters.location = this.$store.getters.getDefaultLocationForRequests(this)
        this.filters.with_childs = this.$store.getters.getDefaultWithChildsForRequests(this);
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject;
      }
      this.toggleShowLocationFilter();

      this.refreshFirewallsList();
    },

    toggleShowLocationFilter() {
      this.showLocationFilter = false;
      this.$nextTick(() => {
        this.showLocationFilter = true;
      })
    },
    deleteFirewall() {
      firewallService.deleteFirewall(this);
    },
    cancelDeleting() {
      this.deletingFirewallModal = false;
    },
    firewallSpinner(forceId) {
      const { firewallOperations } = this.$store.state;
      const firewallRows = document.querySelectorAll('tr');

      firewallRows.forEach((firewallRow) => {
        firewallRow.classList.remove('firewall-loading');
      });
      if (forceId) {
        firewallOperations.forEach((operation) => {
          if (operation.model_id === forceId) {
            setTimeout(() => {
              const firewallRow = document.querySelector(`[data-id="${forceId}"]`);
              if (firewallRow) {
                firewallRow.classList.add('firewall-loading');
              }
            }, 1);
          }
        });
      } else if (firewallOperations.length === 0) {
      } else {
        firewallOperations.forEach((operation) => {
          firewallRows.forEach((firewallRow) => {
            if (firewallRow.dataset.id === operation.model_id) {
              firewallRow.classList.add('firewall-loading');
            }
          });
        });
      }
    },
    addValue(type, exclamation) {
      if (type === 'destination') {
        this.$validator.validateAll({ destination: this.newDestination }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.destination.push(this.newDestination);
            } else {
              this.newRule.destination.push(`! ${this.newDestination}`);
            }
            this.newDestination = '';
            this.$validator.reset();
          }
        });
      }
      if (type === 'source') {
        this.$validator.validateAll({ source: this.newSource }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.source.push(this.newSource);
            } else {
              this.newRule.source.push(`! ${this.newSource}`);
            }
            this.newSource = '';
            this.$validator.reset();
          }
        });
      }
      if (type === 'ip_destination') {
        this.$validator.validateAll({ 'ip-destination': this.newIpDestination }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.ip_destination.push(this.newIpDestination);
            } else {
              this.newRule.ip_destination.push(`! ${this.newIpDestination}`);
            }
            this.newIpDestination = '';
            this.$validator.reset();
          }
        });
      }
      if (type === 'ip_source') {
        this.$validator.validateAll({ 'ip-source': this.newIpSource }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.ip_source.push(this.newIpSource);
            } else {
              this.newRule.ip_source.push(`! ${this.newIpSource}`);
            }
            this.newIpSource = '';
            this.$validator.reset();
          }
        });
      }
      if (type === 'ip_destination_port') {
        this.$validator.validateAll({ 'port-destination': this.newPortDestination }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.ip_destination_port.push(this.newPortDestination);
            } else {
              this.newRule.ip_destination_port.push(`! ${this.newPortDestination}`);
            }
            this.newPortDestination = '';
            this.$validator.reset();
          }
        });
      }
      if (type === 'ip_source_port') {
        this.$validator.validateAll({ 'port-source': this.newPortSource }).then((result) => {
          if (result) {
            if (!exclamation) {
              this.newRule.ip_source_port.push(this.newPortSource);
            } else {
              this.newRule.ip_source_port.push(`! ${this.newPortSource}`);
            }
            this.newPortSource = '';
            this.$validator.reset();
          }
        });
      }
    },
    removeValue(e) {
      const selectedNode = e.target.closest('[data-id]');
      const { id } = selectedNode.dataset;
      const { type } = selectedNode.dataset;
      if (type === 'destination') {
        this.newRule.destination.splice(id, 1);
      }
      if (type === 'source') {
        this.newRule.source.splice(id, 1);
      }
      if (type === 'ip_destination') {
        this.newRule.ip_destination.splice(id, 1);
      }
      if (type === 'ip_source') {
        this.newRule.ip_source.splice(id, 1);
      }
      if (type === 'ip_destination_port') {
        this.newRule.ip_destination_port.splice(id, 1);
      }
      if (type === 'ip_source_port') {
        this.newRule.ip_source_port.splice(id, 1);
      }
    },
    toggleRuleEdit(e) {
      if (this.isDisable) {
        return;
      }
      const selectedNode = e.target.closest('[data-index]');
      const index = parseInt(selectedNode.dataset.index);

      if (this.ruleIndexForEdit === index) {
        this.ruleIndexForEdit = false;
        this.newRule = {
          destination: [],
          source: [],
          ip_destination: [],
          ip_source: [],
          ip_destination_port: [],
          ip_source_port: [],
          ip_protocol: '',
          jump: 'ACCEPT',
          protocol: ''
        };
        return;
      }
      this.ruleIndexForEdit = index;
      this.newRule = JSON.parse(JSON.stringify(this.updatedFirewall.rules[index]));
      this.editRuleModal = true;
    },
    refreshFirewallsList() {
      // firewallService.getFirewalls(this);
      firewallService.getFirewalls(this, true);

    },
    pushNewRule() {
      if (this.newDestination) {
        this.newRule.destination.push(this.newDestination);
      }
      if (this.newSource) {
        this.newRule.source.push(this.newSource);
      }
      if (this.newIpDestination) {
        this.newRule.ip_destination.push(this.newIpDestination);
      }
      if (this.newIpSource) {
        this.newRule.ip_source.push(this.newIpSource);
      }
      if (this.newPortDestination) {
        this.newRule.ip_destination_port.push(this.newPortDestination);
      }
      if (this.newPortSource) {
        this.newRule.ip_source_port.push(this.newPortSource);
      }

      if ((this.ruleIndexForEdit || this.ruleIndexForEdit === 0) && this.updatedFirewall.rules[this.ruleIndexForEdit]) {
        this.updatedFirewall.rules[this.ruleIndexForEdit] = JSON.parse(JSON.stringify(this.newRule));
      } else {
        this.updatedFirewall.rules.push(JSON.parse(JSON.stringify(this.newRule)));
      }
      this.editRuleModal = false;
      this.ruleIndexForEdit = false;
      this.clearFields();
    },
    deleteRule() {
      this.editRuleModal = false;
      this.updatedFirewall.rules.splice(this.ruleIndexForEdit, 1);
      this.ruleIndexForEdit = false;
      this.clearFields();
    },
    cancelRule() {
      this.editRuleModal = false;
      this.ruleIndexForEdit = false;
      this.clearFields();
    },
    clearFields() {
      this.newDestination = '';
      this.newSource = '';
      this.newIpDestination = '';
      this.newIpSource = '';
      this.newPortDestination = '';
      this.newPortSource = '';
      this.newRule = {
        destination: [],
        source: [],
        ip_destination: [],
        ip_source: [],
        ip_destination_port: [],
        ip_source_port: [],
        ip_protocol: '',
        jump: 'ACCEPT',
        protocol: ''
      };
    },
    locationName(id) {
      const showLocationFromId = commonService.showLocationFromId(id);
      return showLocationFromId;
    },
    openFirewalleWizard() {
      this.$router.push({ name: 'firewall-wizard' });
      this.$store.commit('setFirewallEditId', false);
      this.$store.commit('clearNewFirewallData');
    },
    disableEditMode() {
      this.$store.commit('setFirewallEditId', null);
    },
    updateFirewall() {
      firewallService.updateFirewall(this);
    },
    firewallName(id) {
      const firewallName = commonService.firewallNamebyId(id);
      return firewallName;
    }
  },
  // created() {
  //   firewallService.getFirewalls(this);
  //   this.disableEditMode();
  //   this.clearFields();
  //   this.$store.commit('setFirewallEditId', false);
  // }
  created() {
    let inHeaderLocationSelectedApplying = true;
    if (this.$store.state.inHeaderLocationSelectModeEnabled &&
      inHeaderLocationSelectedApplying) {
      let withChildsForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.withChilds;
      let locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationID;
      if (!locationForFiltering || locationForFiltering === '') {
        // locationForFiltering = this.$store.getters.getInHeaderLocationSelectBlockData.userBaseLocation
        locationForFiltering = 'All locations';
      }

      if (locationForFiltering === 'All locations') {
        this.locationInFiltersData = [];
      } else {
        this.locationInFiltersData = this.$store.getters.getInHeaderLocationSelectBlockData.selectedLocationObject
      }

      this.filters.location = locationForFiltering;
      this.filters.with_childs = withChildsForFiltering;
    }

    firewallService.getFirewalls(this, true);
    this.disableEditMode();
    this.clearFields();
    this.$store.commit('setFirewallEditId', false);
  }
};
</script>
<style lang="scss">
          .input-group-for-location-select-in-firewall--filters {

    position: relative;
    display: flex;

    align-items: stretch;
    width: 100%;
    max-width: 100%;

  }
      .select-location-in-firewall--filters .select__list-container {
    width: 400px;
  }
.firewalls {
  display: flex;
}

.firewalls-list,
.firewall-edit {
  transition: width 0.2s;
}

.firewalls-list {
  width: 100%;
}

.firewall-edit {
  display: inline-block;
  width: 0%;
}

.edit-visible .firewalls-list {
  transition: width, margin-right 0.2s;
  margin-right: 10px;
  width: 20%;
}

.edit-visible .firewall-edit {
  transition: width 0.2s;
  width: 80%;
  min-width: 500px;
}

.l2-value {
  display: block;
}

.rule {
  cursor: pointer;
}

.rule-disable {
  cursor: default !important;
  background-color: #ffffff !important;
}

.firewall-loading {
  animation-name: blink;
  animation-timing-function: linear;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}
.loader-backdrop-firewall:after {
  position: absolute;
  top: 30px;
}
.edit-btn-close {
  line-height: 14px;
  font-size: 14px !important;
  height: 27.5px;
  width: 29px;
}
.info-tooltip-in-firewall {
  margin-left: 0.2em;
}
</style>
